import { useEffect, useRef } from "react";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";
import { History } from "./History";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const AccounInfo = () => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);
  const navigate = useNavigate();
  const languageStatus = useSelector((state) => state.currentLanguage);

  if (!localStorage.getItem("access")) {
    navigate("/");
  }

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  return (
    <motion.div
      className="accountBox"
      ref={rootRef}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
    >
      <div className="accountInfoBox">
        <div className="accountInfoContent">
          <div className="title accountTitle">
            {languageStatus ? "My account" : "Мой профиль"}
          </div>
          <div className="infoBox accaountInfo">
            <div className="infoTitle">
              {languageStatus ? "Wallet" : "Кошелёк"}:
            </div>
            <div>12345678</div>
          </div>
          <div className="infoBox accaountInfo">
            <div className="infoTitle">
              {languageStatus ? "Card" : "Карта"}:
            </div>
            <div>SBER **7789</div>
          </div>
          <div className="infoBox accaountInfo">
            <div className="infoTitle">E-MAIL:</div>
            <div>{localStorage.getItem("email")}</div>
          </div>
          <div className="infoBox accaountInfo">
            <div className="infoTitle">
              {" "}
              {languageStatus ? "Phone number" : "Телефон"}:
            </div>
            <div>{localStorage.getItem("phone")}</div>
          </div>
        </div>
        <div className="depositBox">
          <div className="title accountTitle">
            {languageStatus ? "Deposit" : "Депозит"}
          </div>
          <div className="infoBox">
            <div className="infoTitle">BTC:</div>
            <div>0</div>
          </div>
          <div className="infoBox">
            <div className="infoTitle">ETH:</div> <div>0</div>
          </div>
          <div className="infoBox">
            <div className="infoTitle">XRP:</div> <div>0</div>
          </div>
          <div className="infoBox">
            <div className="infoTitle">USDT:</div> <div>0</div>
          </div>
          <div className="infoBox">
            <div className="infoTitle">USDC:</div> <div>0</div>
          </div>
          <div className="infoBox">
            <div className="infoTitle">LTC:</div> <div>0</div>
          </div>
          <div className="infoBox">
            <div className="infoTitle">XMR:</div> <div>0</div>
          </div>
          <div className="infoBox">
            <div className="infoTitle">SOL:</div> <div>0</div>
          </div>
          <div className="infoBox">
            <div className="infoTitle">BNB:</div> <div>0</div>
          </div>
        </div>
      </div>
      <History></History>
    </motion.div>
  );
};
