import { useEffect, useRef } from "react";
import { ExchangeWindow } from "./ExchangeWindow";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";
import { CryptoAdres } from "./CryptoAdres";
import { useSelector } from "react-redux";
import { cryptoRef, store } from "../Store/Store";

export const Exchanger = () => {
  const controls = useAnimation();
  const rootRef = useRef();
  const cryptoAdresRef = useRef();
  const onScreen = useOnScreen(rootRef);
  const cryptoCoinRef = useSelector((state) => state.currentCryptoRef);
  const languageStatus = useSelector((state) => state.currentLanguage);

  const executeCryptoScroll = () =>
    cryptoAdresRef.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });

  useEffect(() => {
    if (cryptoCoinRef == true) {
      executeCryptoScroll();
      store.dispatch(cryptoRef(false));
    }
  }, []);

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 1,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  return (
    <motion.div
      className="mainExchangerBox desktopExchanger"
      ref={rootRef}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
    >
      <div className="title mainExchangeTitle">
        {languageStatus ? "Cryptocurrency Exchange" : "Обмен криптовалюты"}
      </div>
      <div className="mainExchangeGuid">
        <div className="exchangeInstruction">
          <div className="guidInstruction">
            <div className="guidNumber">1.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Exchange pair" : "Обменная пара"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Select the desired exchange pair"
                  : "Выберите желаемую обменную пару"}
              </div>
            </div>
          </div>{" "}
          <div className="guidInstruction">
            <div className="guidNumber">3.</div>
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {" "}
                {languageStatus ? "Payment" : "Оплата"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Deposit the required amount for the exchange"
                  : "Внесите необходимую сумму для обмена"}
              </div>
            </div>
          </div>{" "}
          <div className="guidInstruction">
            <div className="guidNumber">2.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus ? "Wallet address" : "Адрес кошелька"}
              </div>
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "Fill in your cryptocurrency wallet or bank card details"
                  : "Заполните данные криптокошелька или банковской карты"}
              </div>
            </div>
          </div>
          <div className="guidInstruction">
            <div className="guidNumber">4.</div>{" "}
            <div className="guidInstructionTitle">
              <div className="guidTitle exchangerGuidTitle">
                {languageStatus
                  ? "Check your cryptocurrency wallet"
                  : "Проверьте свой криптокошелек"}
              </div>{" "}
              <div className="guidInfo exchangerGuidInfo">
                {languageStatus
                  ? "The exchanged cryptocurrency will arrive to your cryptocurrency wallet within minutes"
                  : "Обменная криптовалюта поступит на ваш криптокошелек в течение нескольких минут"}
              </div>
            </div>
          </div>
        </div>
        <div className="desktopWindow" ref={cryptoAdresRef}>
          <div className="exchangerTerminalTitle">
            {languageStatus ? "Calculate the amount" : "Рассчитать сумму"}
          </div>
          <div className="exchangerTerminalContent">
            {" "}
            <ExchangeWindow></ExchangeWindow>
            <CryptoAdres></CryptoAdres>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
