import { Line } from "react-chartjs-2";

export const AboutGraph = ({ item , close}) => {

  function onClose(){
    close(false)
  }

  return (
    <div className="pop_up" onClick={onClose}>
      {" "}
      <div className="aboutGraph">
        {" "}
        <Line
          type="line"
          width={20}
          height={10}
          data={{
            labels: item.history.map((coin) => {
              let date = new Date(coin[0]);
              let time = `${date.getDate()}.${
                date.getMonth() + 1 < 10
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1
              } ${date.getHours()}:${
                date.getMinutes() < 10
                  ? "0" + date.getMinutes()
                  : date.getMinutes()
              }`;
              return time;
            }),

            datasets: [
              {
                data: item.history.map((coin) => coin[1]),
                borderColor: `${item.color}`,
                fill: true,
                lineTension: 0.5,
              },
            ],
          }}
          options={{
            legend: {
              display: false,
            },

            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                },
              ],
              // yAxes: [
              //   {
              //     ticks: {
              //       display: false,
              //     },
              //   },
              // ],
            },
            elements: {
              point: {
                backgroundColor: `${item.color}`,
              },
            },
          }}
        />
      </div>
    </div>
  );
};
