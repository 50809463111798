import { useEffect, useRef } from "react";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";
import { useSelector } from "react-redux";

export const Contact = () => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);
  const languageStatus = useSelector((state) => state.currentLanguage);
  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);

  return (
    <motion.div
      className="contactBox"
      ref={rootRef}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
    >
      <div className="contactWindow">
        <div className="contactTitle">
          {languageStatus ? "CONTACT US" : "СВЯЖИТЕСЬ С НАМИ"}
        </div>
        <div className="contactInputBox">
          <input className="contactInput" placeholder="EMAIL..."></input>
          <textarea
            className="contactInput contactMessage"
            placeholder={languageStatus ? "MESSAGE..." : "СООБЩЕНИЕ..."}
          ></textarea>
        </div>
        <div className="sendBox">
          <button className="startExchange glowing">
            {languageStatus ? "SEND" : "ОТПРАВИТЬ"}
          </button>
        </div>
      </div>
    </motion.div>
  );
};
