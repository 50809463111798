import { createStoreHook } from "react-redux";
import { combineReducers, createStore } from "redux";

const SWITCH_THEME = "SWITCH_THEME";
const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";
const SWITCH_LOGIN = "SWITCH_LOGIN";
const SWITCH_CHAT = "SWITCH_CHAT";
const SWITCH_REGISTRATION_EXCHANGE = "SWITCH_REGISTRATION_EXCHANGE";
const SWITCH_CONTINUE = "SWITCH_CONTINUE";
const TRANSITION_SUM = "TRANSITION_SUM";
const SWITCH_NUMBER = "SWITCH_NUMBER";
const SWITCH_ADDRES = "SWITCH_ADDRES";
const CURRANCY_REF = "CURRANCY_REF";
const PAIRS_REF = "PAIRS_REF";
const COIN_REF = "PAIRS_REF";
const CRYPTO_REF = "CRYPTO_REF";
const CURRENT_CURRANCY = "CURRENT_CURRANCY";
const LK_COIN = "LK_COIN";
const PROFILE_SWITCH = "PROFILE_SWITCH";
const DOLLAR_STATUS = "DOLLAR_STATUS";

export function switchTheme(status) {
  return {
    type: SWITCH_THEME,
    status,
  };
}

export function switchLanguage(language) {
  return {
    type: SWITCH_LANGUAGE,
    language,
  };
}

export function switchLogin(status) {
  return {
    type: SWITCH_LOGIN,
    status,
  };
}

export function switchChat(status) {
  return {
    type: SWITCH_CHAT,
    status,
  };
}

export function switchRegistrationExchange(status) {
  return {
    type: SWITCH_REGISTRATION_EXCHANGE,
    status,
  };
}

export function switchContinue(status) {
  return {
    type: SWITCH_CONTINUE,
    status,
  };
}

export function switchNumber(status) {
  return {
    type: SWITCH_NUMBER,
    status,
  };
}

export function switchAddres(status) {
  return {
    type: SWITCH_ADDRES,
    status,
  };
}

export function transitionSum(sum, final) {
  return {
    type: TRANSITION_SUM,
    sum,
    final,
  };
}

export function currancyRef(ref) {
  return {
    type: CURRANCY_REF,
    ref,
  };
}

export function pairsRef(ref) {
  return {
    type: PAIRS_REF,
    ref,
  };
}

export function coinRef(ref) {
  return {
    type: COIN_REF,
    ref,
  };
}

export function cryptoRef(ref) {
  return {
    type: CRYPTO_REF,
    ref,
  };
}

export function currancy(currancy) {
  return {
    type: CURRENT_CURRANCY,
    currancy,
  };
}

export function lkCoin(coin) {
  return {
    type: LK_COIN,
    coin,
  };
}

export function profileSwitch(profile) {
  return {
    type: PROFILE_SWITCH,
    profile,
  };
}

export function dollarStatus(dollar) {
  return {
    type: DOLLAR_STATUS,
    dollar,
  };
}

function currentTheme(state = "", action) {
  switch (action.type) {
    case SWITCH_THEME:
      return action.status;
    default:
      return state;
  }
}

function currentLanguage(state = "", action) {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      return action.language;
    default:
      return state;
  }
}

function currentLoginStatus(state = "", action) {
  switch (action.type) {
    case SWITCH_LOGIN:
      return action.status;
    default:
      return state;
  }
}

function currentChatStatus(state = "", action) {
  switch (action.type) {
    case SWITCH_CHAT:
      return action.status;
    default:
      return state;
  }
}

function currentContinue(state = "", action) {
  switch (action.type) {
    case SWITCH_CONTINUE:
      return action.status;
    default:
      return state;
  }
}

function currentRegistrationExchange(state = "", action) {
  switch (action.type) {
    case SWITCH_REGISTRATION_EXCHANGE:
      return action.status;
    default:
      return state;
  }
}

function currentNumber(state = "", action) {
  switch (action.type) {
    case SWITCH_NUMBER:
      return action.status;
    default:
      return state;
  }
}

function currentAddres(state = "", action) {
  switch (action.type) {
    case SWITCH_ADDRES:
      return action.status;
    default:
      return state;
  }
}

function currentTransitionSum(state = "", action) {
  switch (action.type) {
    case TRANSITION_SUM:
      return { ...action, currentSum: action.payload };
    default:
      return state;
  }
}

function currentCurrancyRef(state = "", action) {
  switch (action.type) {
    case CURRANCY_REF:
      return action.ref;
    default:
      return state;
  }
}

function currentPairsRef(state = "", action) {
  switch (action.type) {
    case PAIRS_REF:
      return action.ref;
    default:
      return state;
  }
}

function currentCoinRef(state = "", action) {
  switch (action.type) {
    case COIN_REF:
      return action.ref;
    default:
      return state;
  }
}

function currentCryptoRef(state = "", action) {
  switch (action.type) {
    case CRYPTO_REF:
      return action.ref;
    default:
      return state;
  }
}

function currentCurrancy(state = "", action) {
  switch (action.type) {
    case CURRENT_CURRANCY:
      return action.currancy;
    default:
      return state;
  }
}

function currentLKCoin(state = "", action) {
  switch (action.type) {
    case LK_COIN:
      return action.coin;
    default:
      return state;
  }
}

function currentProfileSwitch(state = "", action) {
  switch (action.type) {
    case PROFILE_SWITCH:
      return action.profile;
    default:
      return state;
  }
}

function currentDollarStatus(state = "", action) {
  switch (action.type) {
    case DOLLAR_STATUS:
      return action.dollar;
    default:
      return state;
  }
}

const cryptoAction = combineReducers({
  currentTheme,
  currentLanguage,
  currentLoginStatus,
  currentChatStatus,
  currentRegistrationExchange,
  currentContinue,
  currentTransitionSum,
  currentNumber,
  currentAddres,
  currentCurrancyRef,
  currentPairsRef,
  currentCoinRef,
  currentCurrancy,
  currentLKCoin,
  currentCryptoRef,
  currentProfileSwitch,
  currentDollarStatus,
});

export const store = createStore(cryptoAction);
