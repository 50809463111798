import { AccounInfo } from "../Components/AccountInfo";
import Footer from "../Components/Footer";
import { Header } from "../Components/Header";
import { CurancyLK } from "../Components/CurrancyLK";
import { ChatButton } from "../Components/Chat";
import { ExchangeWindowLK } from "../Components/ExchangeWindowLK";
import { profileSwitch, store } from "../Store/Store";
import { useSelector } from "react-redux";

export const Account = () => {
  store.dispatch(profileSwitch(true));

  const themeStatus = useSelector((state) => state.currentTheme);

  return (
    <div>
      <div className={themeStatus == "dark" ? "mainBox" : "mainBoxLight"}>
        {" "}
        <div className="light x1"></div>
        <div className="light x3"></div>
        <div className="light x5"></div>
        <div className="light x7"></div>
        <div className="light x9"></div>
        <Header></Header>
        <AccounInfo></AccounInfo>
        <CurancyLK></CurancyLK>
        <div className="accountExchanger">
          <ExchangeWindowLK></ExchangeWindowLK>
        </div>
        <ChatButton></ChatButton>
      </div>{" "}
      <Footer></Footer>{" "}
    </div>
  );
};
