import axios from "axios";
import { store, switchContinue, switchLogin } from "../Store/Store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export function ContinuePop() {
  const navigate = useNavigate();
  const languageStatus = useSelector((state) => state.currentLanguage);

  async function handleClick() {
    store.dispatch(switchContinue(false));
    document.body.style.overflow = "";

    await axios
      .get("https://point1.bot-n.ru/register_email", {
        params: { email: localStorage.getItem("email") },
      })
      .then((response) => {
        document.body.style.overflow = "";

        localStorage.setItem("access", response.data.temporary_access_token);

        navigate(`/transition`);
      });
  }

  function handleContinue() {
    store.dispatch(switchLogin(true));
    store.dispatch(switchContinue(false));
  }

  return (
    <div className="pop_up">
      <div className="pop_window  continueWindow">
        <div className="signUpContent">
          <div className="conentPart">
            {" "}
            <div className="continueTitle">
              {languageStatus
                ? "Would you like to log in to your profile to continue sharing?"
                : "Хотите войти в профиль для продолжения обмена?"}
            </div>
          </div>
          <div className="conentPart"></div>
          <div className="conentPart">
            <button className="enterButton" onClick={handleContinue}>
              {languageStatus ? "Yes" : "Да"}
            </button>
            <button className="enterButton noContinue" onClick={handleClick}>
              {languageStatus ? "No" : "Нет"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
