import { useSelector } from "react-redux";
import { Exchanger } from "../Components/Exchanger";
import Footer from "../Components/Footer";
import { Header } from "../Components/Header";
import { profileSwitch, store } from "../Store/Store";

export const ExchangerPage = () => {
  store.dispatch(profileSwitch(false));

  const themeStatus = useSelector((state) => state.currentTheme);

  return (
    <div>
      <div className={themeStatus == "dark" ? "mainBox" : "mainBoxLight"}>
        {" "}
        <div className="light x1"></div>
        <div className="light x3"></div>
        <div className="light x5"></div>
        <div className="light x7"></div>
        <div className="light x9"></div>
        <Header></Header>
        <Exchanger></Exchanger>
      </div>
      <Footer></Footer>
    </div>
  );
};
