import { useEffect, useState } from "react";
import { ChatWindow } from "./ChatWindow";
import open from "../icons/icons8-чат-100 1.png";
import { store, switchChat } from "../Store/Store";
import { useSelector } from "react-redux";

export const ChatButton = () => {
  const [chat, setChat] = useState(false);
  const chatStatus = useSelector((state) => state.currentChatStatus);

  window.addEventListener("click", (e) => {
    const target = e.target;
    if (
      !target.closest(".chatWindowBox") &&
      !target.closest(".chatButtonBox")
    ) {
      store.dispatch(switchChat(false));
    }
  });

  useEffect(() => {
    setChat(chatStatus);
  });

  return (
    <div className="chatButtonBox">
      <div
        className="chatButton"
        onClick={() => {
          store.dispatch(switchChat(true));
        }}
      >
        <img src={open}></img>
      </div>
      {chat && <ChatWindow></ChatWindow>}
    </div>
  );
};
