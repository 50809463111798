import { AboutAccount } from "../Components/AboutAccount";
import { ChatButton } from "../Components/Chat";
import { Contact } from "../Components/Contact";
import { Curancy } from "../Components/Currancy";
import { ExchangeGuid } from "../Components/ExchangeGuid";
import { ExchangeHome } from "../Components/ExchangeHome";
import { ExchangePaires } from "../Components/ExchangePairs";
import Footer from "../Components/Footer";
import { Header } from "../Components/Header";
import { profileSwitch, store } from "../Store/Store";
import { useSelector } from "react-redux";

export const MainPage = () => {
  store.dispatch(profileSwitch(false));

  const themeStatus = useSelector((state) => state.currentTheme);


  return (
    <div>
      {" "}
      <div className={themeStatus == "dark" ? "mainBox" : "mainBoxLight"}>
        <div>
          {" "}
          <div className={themeStatus == "dark" ? "light x1" : "light x1 whiteLight"}></div>
          <div className={themeStatus == "dark" ? "light x3" : "light x3 whiteLight"}></div>
          <div className={themeStatus == "dark" ? "light x5" : "light x5 whiteLight"}></div>
          <div className={themeStatus == "dark" ? "light x7" : "light x8 whiteLight"}></div>
          <div className={themeStatus == "dark" ? "light x9" : "light x9 whiteLight"}></div>
        </div>

        <Header></Header>
        <ExchangeHome></ExchangeHome>
        <Curancy></Curancy>
        <ExchangePaires></ExchangePaires>
        <AboutAccount></AboutAccount>
        <ExchangeGuid></ExchangeGuid>
        <Contact></Contact>
        <ChatButton></ChatButton>
      </div>{" "}
      <Footer></Footer>
    </div>
  );
};
