import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Exchange from "../Api/ExchangeApi/ExchangeApi";
import { useState } from "react";

export function ContinueTransitionPop({ sumStatus, setTransaction, callback }) {
  const navigate = useNavigate();
  const languageStatus = useSelector((state) => state.currentLanguage);
  const [closeStatus, setCloseStatus] = useState(false);

  async function handleNew() {
    document.body.style.overflow = "";

    const deleteActive = async () => {
      await axios.delete(
        `https://point1.bot-n.ru/ton/del_deal/${localStorage.getItem(
          "tonToken"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        }
      );
    };

    callback(closeStatus);

    await deleteActive();

    await Exchange.fetchTONtoFIAT(sumStatus, setTransaction);

    setCloseStatus(true);
  }

  function handleContinue() {
    callback(closeStatus);
  }

  return (
    <div className="pop_up">
      <div className="pop_window  continueWindow">
        <div className="signUpContent">
          <div className="conentPart">
            {" "}
            <div className="continueTitle">
              {languageStatus
                ? "You already have an active transaction. Do you want to continue an active transaction or start a new one?"
                : "У Вас уже есть активная транзакция. Продолжить активную транзакцию или начать новую?"}
            </div>
          </div>
          <div className="conentPart">
            <button className="enterButton" onClick={handleContinue}>
              {languageStatus ? "Continue" : "Продолжить"}
            </button>
            <button className="enterButton noContinue" onClick={handleNew}>
              {languageStatus ? "Start new" : "Начать новую"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
