import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useOnScreen } from "./hooks/OnScreen";
import { pairsRef, store } from "../Store/Store";
import { useSelector } from "react-redux";
import AliceCarousel from "react-alice-carousel";

export const ExchangePaires = () => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);

  const themeStatus = useSelector((state) => state.currentTheme);

  store.dispatch(pairsRef(rootRef));

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);

  const smallPairs = [
    ["USD", "BTC"],
    ["ETH", "USD"],
    ["USDC", "SOL"],
  ];
  const bigPairs = [
    ["USD", "BTC"],
    ["ETH", "USD"],
    ["USDC", "SOL"],
    ["SOL", "BTC"],
    ["TON", "BTC"],
  ];
  const [pairs, setPairs] = useState(smallPairs);
  const [historicData, setHistoricData] = useState();
  const [loading, setLoad] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoad(true);
      await axios
        .get(`https://point1.bot-n.ru/graphs_coin_period?coin=bitcoin&period=7`)
        .then((response) => {
          setHistoricData(response.data.graph.prices);
          setLoad(false);
        });
    }

    if (status == true) {
      setPairs([]);
      setPairs(bigPairs);
      fetchData();
    }

    if (status == false) {
      setPairs([]);
      setPairs(smallPairs);
      fetchData();
    }
  }, [status]);

  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <div
      onDragStart={handleDragStart}
      role="presentation"
      className={
        themeStatus == "light"
          ? "currencyCard exchangerBoxLight"
          : "currencyCard"
      }
    >
      <div className="pairTitles">
        <div className="pairValues">SOON</div>
      </div>
    </div>,
    <div
      onDragStart={handleDragStart}
      role="presentation"
      className={
        themeStatus == "light"
          ? "currencyCard exchangerBoxLight"
          : "currencyCard"
      }
    >
      <div className="pairTitles">
        <div className="pairValues">SOON</div>
      </div>
    </div>,
    <div
      onDragStart={handleDragStart}
      role="presentation"
      className={
        themeStatus == "light"
          ? "currencyCard exchangerBoxLight"
          : "currencyCard"
      }
    >
      <div className="pairTitles">
        <div className="pairValues">SOON</div>
      </div>
    </div>,
  ];

  return (
    <motion.div
      className="exchangePairesBox"
      ref={rootRef}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
    >
      <div
        className={
          themeStatus == "dark"
            ? "title exchangePairesTitle"
            : "title exchangePairesTitle titleDark"
        }
      >
        SPECIAL
      </div>
      <div className="cardBoxMobile">
        <AliceCarousel mouseTracking items={items} />
      </div>

      <div className="cardBox">
        {pairs.map((pair) => {
          return (
            <div
              key={pair}
              className={
                themeStatus == "light"
                  ? "currencyCard exchangerBoxLight"
                  : "currencyCard"
              }
            >
              <div className="pairTitles">
                <div className="pairValues">
                  {/* <span>{pair[0]}</span>
                  <div className="bulletBox">
                    <div
                      className={
                        themeStatus == "light" ? "line lightLine" : "line"
                      }
                    >
                      <div className="bullet"></div>
                    </div>
                  </div>
                  <span>{pair[1]}</span> */}
                  SOON
                </div>
              </div>
              {/* <div className="pairCardBox">1 ETH = 0,0641 BTC</div> */}
              {/* <div className="pairCard">
                {loading ? (
                  <ReactLoading
                    type="spinningBubbles"
                    color="white"
                    height={"50px"}
                    width={"50px"}
                  />
                ) : (
                  <div className="chart cardChart">
                    {historicData && (
                      <Line
                        type="line"
                        width={80}
                        height={40}
                        data={{
                          labels: historicData.map((coin) => {
                            let date = new Date(coin[0]);
                            let time =
                              date.getHours() > 12
                                ? `${
                                    date.getHours() - 12
                                  }:${date.getMinutes()} PM`
                                : `${date.getHours()}:${date.getMinutes()} AM`;
                            return time;
                          }),

                          datasets: [
                            {
                              data: historicData.map((coin) => coin[1]),

                              borderColor: 'green',

                              background: "none",
                            },
                          ],
                        }}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  display: false,
                                },
                                gridLines: {
                                  display: false,
                                  color: "rgba(0, 0, 0, 0)",
                                },
                              },
                            ],
                            yAxes: [
                              {
                                ticks: {
                                  display: false,
                                },
                                gridLines: {
                                  display: false,
                                  color: "rgba(0, 0, 0, 0)",
                                },
                              },
                            ],
                          },
                          elements: {
                            point: {
                              radius: 1,
                            },
                          },
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "45px",
                  marginLeft: "90px",
                }}
              >
                2.2$
              </div> */}
            </div>
          );
        })}
      </div>
      {/* {!status && (
        <div className="moreCurrancy" onClick={() => setStatus(true)}>
          Показать больше
        </div>
      )}
      {status && (
        <div className="moreCurrancy" onClick={() => setStatus(false)}>
          Свернуть
        </div>
      )} */}
    </motion.div>
  );
};
