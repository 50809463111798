import axios from "axios";

class Exchange {
  static async fetchBTCtoFIAT(sumStatus) {
    let transactionAddres = "";
    let status = "";
    let addres = "";

    await axios
      .get(`https://point1.bot-n.ru/address_bitcoin/${sumStatus.sum}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        localStorage.setItem("transactionAddres", response.data.address);
        transactionAddres = response.data.rand_name;
      });

    const getStatus = async () => {
      await axios
        .get(
          `https://point1.bot-n.ru/address_bitcoin_status/${transactionAddres}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        )
        .then((response) => {
          addres = response.data.wal_address;
          localStorage.setItem("coinAmount", response.data.amount);

          if (response.data.status === "done") {
            if (
              localStorage.getItem("accountStatus") == null &&
              JSON.parse(localStorage.getItem("uniq")) !== false
            ) {
              alert("Пароль аккаунта отправлен на почту");
            }
            status = response.data.status;
            clearInterval(connectInterval);
          }
        });
    };

    await getStatus();
    const connectInterval = setInterval(getStatus, 15000);

    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (status === "done") {
          clearInterval(intervalId);
          resolve({ status, addres });
        }
      }, 1000);
    });
  }
  static async checkFetchTONtoFIAT(setActive) {
    let active = false;

    await axios
      .get("https://point1.bot-n.ru/ton/address", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {});

    const checkActive = async () => {
      await axios
        .get("https://point1.bot-n.ru/ton/view_active_deal", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.data === null) {
            active = false;
          } else {
            active = true;
            setActive({ active });
            return active;
          }
        });
    };

    await checkActive();
  }

  static async fetchTONtoFIAT(sumStatus, setTransaction) {
    let status = "";
    let addres = "";
    let token = "";
    let active = false;

    await axios
      .get("https://point1.bot-n.ru/ton/address", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {});

    const checkActive = async () => {
      await axios
        .get("https://point1.bot-n.ru/ton/view_active_deal", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          if (response.data === null) {
            active = false;
          } else {
            active = true;
            setTransaction({ status, addres });
            return active;
          }
        });
    };

    await checkActive();

    if (active === false) {
      await axios
        .post("https://point1.bot-n.ru/ton/create_deal", null, {
          params: {
            amount: sumStatus || localStorage.getItem("coinAmount"),
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          token = response.data.deal_token;
          localStorage.setItem("tonToken", token);
        });

      const getStatus = async () => {
        await axios
          .get(`https://point1.bot-n.ru/ton/view_deal/${token}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          })
          .then((response) => {
            addres = response.data.wal_address;
            status = response.data.status;
            localStorage.setItem("coinAmount", response.data.amount);

            setTransaction({ status, addres });

            if (status === "Get" || status === "Stop") {
              clearInterval(tonInterval);
            }
          });
      };

      const tonInterval = setInterval(getStatus, 15000);
      await getStatus();
    }

    if (active === true) {
      const viewActive = async () => {
        await axios
          .get("https://point1.bot-n.ru/ton/view_active_deal", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          })
          .then((response) => {
            if (response.data === null) {
              clearInterval(tonActive);
            } else {
              addres = response.data.wal_address;
              status = response.data.status;
              localStorage.setItem("coinAmount", response.data.amount);
              localStorage.setItem("tonToken", response.data.deal_token);

              setTransaction({ status, addres });

              if (status === "Get" || status === "Stop") {
                clearInterval(tonActive);
              }
            }
          });
      };

      const tonActive = setInterval(viewActive, 15000);
      await viewActive();
    }
  }
}

export default Exchange;
