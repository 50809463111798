import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const languageStatus = useSelector((state) => state.currentLanguage);

  const executeContactScroll = () => {
    navigate("/");

    setTimeout(() => {
      document.querySelector(".contactWindow").scrollIntoView({
        block: "center",
        inline: "nearest",
      });
    }, 800);
  };

  return (
    <div className="footer">
      <div className="footerLogo">ORACLE EXCHANGE</div>
      <div className="footerContent">
        <div className="footerButton">
          {languageStatus
            ? "Cryptocurrency profit calculator"
            : "Калькулятор прибыли криптовалюты"}
        </div>
        <div className="footerButton">
          {" "}
          {languageStatus ? "Visitor information" : "Информация для посетителя"}
        </div>
        <div className="footerButton">
          {languageStatus ? "Rules" : "Правила"}
        </div>
        <div className="footerButton">
          {languageStatus ? "Available limits" : "Доступные лимиты"}
        </div>
        <div className="footerButton" onClick={executeContactScroll}>
          {languageStatus ? "Contact us" : "Связаться с нами"}
        </div>
        <div className="footerButton">
          {languageStatus ? "Privacy Policy" : "Политика конфиденциальности"}
        </div>
        <div className="footerButton">
          {languageStatus ? "Offer" : "Оферта"}
        </div>
      </div>
    </div>
  );
}

export default Footer;
