import axios from "axios";
import { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";
import { cryptoRef, currancyRef, store } from "../Store/Store";
import { AboutGraph } from "./AboutGraph";

import btc from "../icons/btc_color.png";
import eth from "../icons/eth_color.png";
import xrp from "../icons/xrp_color.png";
import usdt from "../icons/usdt_color.png";
import usdc from "../icons/usdc_color.png";
import ltc from "../icons/ltc_color.png";
import xmr from "../icons/xmr_color.png";
import sol from "../icons/sol_color.png";
import bnb from "../icons/bnb_color.png";
import ton from "../icons/ton.png";

import { useSelector } from "react-redux";

export const Curancy = () => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);
  // const [graphTheme, setGraphTheme] = useState();

  const themeStatus = useSelector((state) => state.currentTheme);

  useEffect(() => {
    store.dispatch(currancyRef(rootRef));
  }, []);

  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);

  const navigate = useNavigate();
  const [currancyList, setCurrancyList] = useState([]);
  const languageStatus = useSelector((state) => state.currentLanguage);
  const [loading, setLoad] = useState(false);
  const coinIds = [
    { name: "bitcoin", src: btc },
    { name: "ethereum", src: eth },
    { name: "tether", src: usdt },
  ];
  const moreCoinsIds = [
    { name: "bitcoin", src: btc },
    { name: "ethereum", src: eth },
    { name: "tether", src: usdt },
    { name: "usd-coin", src: usdc },
    { name: "solana", src: sol },
    { name: "the-open-network", src: ton },
  ];
  const [graphDateList, setGraphDateList] = useState([
    { date: "1 день", active: false, current: "1" },
    { date: "7 дней", active: true, current: "7" },
    { date: "30 дней", active: false, current: "30" },
  ]);

  const [moreStatus, setMoreStatus] = useState();
  const [coinList, setCoinList] = useState(coinIds);
  const [bigGraph, setBigGraph] = useState(false);
  const [graphItem, setGraphItem] = useState();
  const [currentGraphDate, setCurrentGraphDate] = useState("7");

  const date = new Date();
  date.setDate(date.getDate() - 7);

  // const dateData = `${
  //   date.getDate() < 10 ? `0` + date.getDate() : date.getDate()
  // }-${
  //   date.getMonth() + 1 < 10 ? `0` + date.getMonth() : date.getMonth() + 1
  // }-${date.getFullYear()}`;

  useEffect(() => {
    languageStatus
      ? setGraphDateList([
          { date: "1 day", active: false, current: "1" },
          { date: "7 days", active: true, current: "7" },
          { date: "30 days", active: false, current: "30" },
        ])
      : setGraphDateList([
          { date: "1 день", active: false, current: "1" },
          { date: "7 дней", active: true, current: "7" },
          { date: "30 дней", active: false, current: "30" },
        ]);
  }, [languageStatus]);

  async function fetchData() {
    for (let item in coinList) {
      let price;
      let oldPrice;
      let color;
      let name;
      let symbol;
      let coin;

      setLoad(true);
      await axios
        .get(
          `https://point1.bot-n.ru/graphs_coin_period?coin=${coinList[item].name}&period=${currentGraphDate}`
        )
        .then((response) => {
          setLoad(false);

          price = response.data.now_day.usd;
          oldPrice = response.data.history.market_data.current_price.usd;
          name = response.data.history.name;
          symbol = response.data.history.symbol;
          coin = response.data.coin;

          if ((1 - oldPrice / price) * 100 < 0) {
            color = "#e65069";
          }

          if ((1 - oldPrice / price) * 100 >= 0) {
            color = "#0abf64";
          }
          setCurrancyList((currancyList) => [
            ...currancyList,
            {
              name: name,
              price: Math.round(price * 100) / 100,
              difference: (1 - oldPrice / price) * 100,
              color: color,
              history: response.data.graph.prices,
              symbol: symbol.toUpperCase(),
              coin: coin,
              src: coinList[item].src,
              blackSrc: coinList[item].blackSrc,
            },
          ]);
        });
    }
  }

  useEffect(() => {
    setCurrancyList([]);
    fetchData();
  }, [currentGraphDate, coinList]);

  useEffect(() => {
    if (moreStatus == false) {
      setCoinList(coinIds);
    }

    if (moreStatus == true) {
      setCoinList(moreCoinsIds);
    }
  }, [moreStatus]);

  useEffect(() => {
    if (bigGraph) {
      document.body.style.overflow = "hidden";
    }
    if (!bigGraph) {
      document.body.style.overflow = "";
    }
  });

  window.addEventListener("click", (e) => {});

  function goExchange(item) {
    localStorage.setItem(
      "firstValue",
      JSON.stringify({ title: item.symbol, coin: item.coin })
    );
    localStorage.setItem("valueInput", "");
    navigate("/exchanger");
    store.dispatch(cryptoRef(true));
  }

  function dateClick(item) {
    setGraphDateList(
      graphDateList.map((listItem) => {
        if (item == listItem) {
          setCurrentGraphDate(listItem.current);
          return { ...listItem, active: true };
        } else {
          return { ...listItem, active: false };
        }
      })
    );
  }

  function closeGraph(status) {
    setBigGraph(status);
  }

  return (
    <div>
      <motion.div
        className="currancyBox"
        id="currancyBox"
        ref={rootRef}
        initial={{ opacity: 0, y: -10 }}
        animate={controls}
      >
        <div
          className={
            themeStatus == "dark"
              ? "title popularTitle"
              : "title popularTitle titleDark"
          }
        >
          {languageStatus
            ? "AVAILABLE EXCHANGE RATES"
            : "КУРСЫ ДОСТУПНЫХ ВАЛЮТ"}
        </div>
        <div className="chooseDateBox">
          {graphDateList.map((item) => (
            <div
              key={item.current}
              className={`headerButton ${
                item.active == true
                  ? themeStatus == "light"
                    ? "chosenGraphDate lightBack"
                    : "chosenGraphDate"
                  : ""
              }`}
              onClick={() => dateClick(item)}
            >
              {item.date}
            </div>
          ))}
        </div>
        {/* <div className="popularHeader">
          <span>ВАЛЮТА</span>
          <span>ЦЕНА</span>
          <span>ГРАФИК</span>
          <span>ИЗМЕНЕНИЯ</span>
          <span>КУПИТЬ</span>
        </div> */}

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              minWidth: "100%",
            }}
          >
            <ReactLoading
              type="spinningBubbles"
              color="white"
              height={"50px"}
              width={"50px"}
            />
          </div>
        ) : (
          <div className="currancyList">
            {currancyList.map((item) => {
              return (
                <div className="currancyRow" key={item}>
                  <div className="rowBlock currancyTitle">
                    {" "}
                    <img className="cryptoCoin" src={item.src}></img>
                    {item.name}
                  </div>
                  <div
                    className="rowBlock currancyPrice"
                    style={{ fontFamily: "MonteserratBold" }}
                  >
                    ${Math.round(item.price * 100) / 100}
                  </div>
                  <div className="rowBlock">
                    <div
                      className="chart currencyChart"
                      onClick={() => {
                        if (window.outerWidth > 650) {
                          setBigGraph(true);
                          setGraphItem(item);
                        }
                      }}
                    >
                      <Line
                        type="line"
                        width={20}
                        height={10}
                        data={{
                          labels: item.history.map((coin) => {
                            let date = new Date(coin[0]);
                            let time;
                            if (window.outerWidth > 650) {
                              time = `${date.getHours()}:${
                                date.getMinutes() < 10
                                  ? "0" + date.getMinutes()
                                  : date.getMinutes()
                              }`;
                            } else {
                              time = `${date.getDate()}.${
                                date.getMonth() + 1 < 10
                                  ? "0" + (date.getMonth() + 1)
                                  : date.getMonth() + 1
                              } ${date.getHours()}:${
                                date.getMinutes() < 10
                                  ? "0" + date.getMinutes()
                                  : date.getMinutes()
                              }`;
                            }
                            return time;
                          }),

                          datasets: [
                            {
                              data: item.history.map((coin) => coin[1]),
                              borderColor: `${item.color}`,
                              fill: true,
                              lineTension: 0.5,
                            },
                          ],
                        }}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                ticks: {
                                  display: false,
                                },
                                gridLines: {
                                  display: false,
                                  color: "rgba(0, 0, 0, 0)",
                                },
                              },
                            ],
                            yAxes: [
                              {
                                ticks: {
                                  display: false,
                                },
                                gridLines: {
                                  display: false,
                                  color: "rgba(0, 0, 0, 0)",
                                },
                              },
                            ],
                          },
                          elements: {
                            point: {
                              radius: 1,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="rowBlock"
                    style={{ fontFamily: "MonteserratBold" }}
                  >
                    {Math.round(item.difference * 100) / 100}%
                  </div>
                  <button
                    className={
                      themeStatus == "light"
                        ? "startCurrancyExchange rowBlock glowing lightBack"
                        : "startCurrancyExchange rowBlock glowing"
                    }
                    onClick={() => goExchange(item)}
                  >
                    {languageStatus ? "BUY" : "КУПИТЬ"}
                  </button>
                </div>
              );
            })}
          </div>
        )}

        {!moreStatus && (
          <div
            className="moreCurrancy"
            onClick={() => {
              setMoreStatus(true);
              rootRef.current.scrollIntoView();
            }}
          >
            {languageStatus ? "Show more" : "Показать больше"}
          </div>
        )}
        {moreStatus && (
          <div className="moreCurrancy" onClick={() => setMoreStatus(false)}>
            {languageStatus ? "Collapse" : "Свернуть"}
          </div>
        )}
      </motion.div>{" "}
      {bigGraph && (
        <AboutGraph close={closeGraph} item={graphItem}></AboutGraph>
      )}
    </div>
  );
};
