import { Route, Routes } from "react-router-dom";
import { MainPage } from "./Pages/MainPage";
import { ExchangerPage } from "./Pages/ExchangerPage";
import { Provider } from "react-redux";
import { store } from "./Store/Store";
import { Account } from "./Pages/Account";
import { TransitionStatus } from "./Pages/TransitionStatus";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/exchanger" element={<ExchangerPage />} />
          <Route path="/accaount" element={<Account />} />
          <Route path="/transition" element={<TransitionStatus />} />
        </Routes>
      </Provider>
    </div>
  );
}

export default App;
