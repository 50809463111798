import { useEffect, useRef } from "react";
import { useOnScreen } from "./hooks/OnScreen";
import { motion, useAnimation } from "framer-motion";
import { useSelector } from "react-redux";

export const ExchangeGuid = () => {
  const themeStatus = useSelector((state) => state.currentTheme);

  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useOnScreen(rootRef);
  const languageStatus = useSelector((state) => state.currentLanguage);
  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 2,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  return (
    <motion.div
      className="exchangeGuidBox"
      ref={rootRef}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
    >
      <div
        className={
          themeStatus == "light"
            ? "title exchangeGuidTitle titleDark"
            : "title exchangeGuidTitle"
        }
      >
        {languageStatus
          ? "HOW TO EXCHANGE CRYPTOCURRENCY"
          : "КАК ОБМЕНЯТЬ КРИПТОВАЛЮТУ"}
      </div>
      <div className="guidCardBox">
        <div
          className={
            themeStatus == "light" ? "guidCard exchangerBoxLight" : "guidCard"
          }
        >
          <div className="guidCardTitle">
            <div className="guidNumber">1.</div>
            <div
              className={
                themeStatus == "light"
                  ? "guidTitle guidTitleLight"
                  : "guidTitle"
              }
            >
              {languageStatus
                ? "Create an account in a few steps"
                : "Создайте аккаунт за несколько шагов"}
            </div>
          </div>
          <div
            className={
              themeStatus == "light" ? "guidInfo guidInfoLight" : "guidInfo"
            }
          >
            {" "}
            {languageStatus
              ? "Register or log in to your personal account to track your transactions. You can register using email."
              : "Зарегистрируйтесь или войдите в личный кабинет, чтобы отслеживать свои транзакции. Вы можете зарегистрироваться с помощью электронной почты."}
          </div>
        </div>
        <div
          className={
            themeStatus == "light" ? "guidCard exchangerBoxLight" : "guidCard"
          }
        >
          <div className="guidCardTitle">
            <div className="guidNumber">2.</div>
            <div
              className={
                themeStatus == "light"
                  ? "guidTitle guidTitleLight"
                  : "guidTitle"
              }
            >
              {languageStatus
                ? "Enter your cryptocurrency wallet address"
                : "Введите адрес вашего криптокошелька"}
            </div>
          </div>
          <div
            className={
              themeStatus == "light" ? "guidInfo guidInfoLight" : "guidInfo"
            }
          >
            {languageStatus
              ? "Enter the crypto wallet address where your cryptocurrency will be credited."
              : "Введите адрес криптокошелька, на который будет зачислена ваша криптовалюта."}
          </div>
        </div>{" "}
        <div
          className={
            themeStatus == "light" ? "guidCard exchangerBoxLight" : "guidCard"
          }
        >
          <div className="guidCardTitle">
            <div className="guidNumber">3.</div>
            <div
              className={
                themeStatus == "light"
                  ? "guidTitle guidTitleLight"
                  : "guidTitle"
              }
            >
              {languageStatus
                ? "Select the cryptocurrency pair you want to exchange"
                : "Выберите криптовалютную пару, которую хотите обменять"}
            </div>
          </div>
          <div
            className={
              themeStatus == "light" ? "guidInfo guidInfoLight" : "guidInfo"
            }
          >
            {languageStatus
              ? "Select the cryptocurrency pair you want to exchange. You can exchange digital assets at either fixed or floating rates. floating rate."
              : "Выберите криптовалютную пару, которую хотите обменять. Вы можете обменивать цифровые активы как по фиксированному, так и по плавающему курсу."}
          </div>
        </div>{" "}
        <div
          className={
            themeStatus == "light" ? "guidCard exchangerBoxLight" : "guidCard"
          }
        >
          <div className="guidCardTitle">
            <div className="guidNumber">4.</div>
            <div
              className={
                themeStatus == "light"
                  ? "guidTitle guidTitleLight"
                  : "guidTitle"
              }
            >
              {" "}
              {languageStatus
                ? "Check your cryptocurrency wallet"
                : "Проверьте свой криптокошелек"}
            </div>
          </div>
          <div
            className={
              themeStatus == "light" ? "guidInfo guidInfoLight" : "guidInfo"
            }
          >
            {languageStatus
              ? " The exchanged cryptocurrency will arrive to your cryptocurrency wallet within minutes."
              : " Обмененная криптовалюта поступит на ваш криптокошелек в течение нескольких минут."}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
