import { store, switchChat } from "../Store/Store";
import send from "../icons/icons8-отправить-96-2 1.png";
import helper from "../icons/LOGO CRYPTO_NOTBACK-2.0.png";
import { useSelector } from "react-redux";

export const ChatWindow = () => {
  const languageStatus = useSelector((state) => state.currentLanguage);

  return (
    <div className="chatWindowBox">
      <div className="chatHead">
        <div className="chatHeadContent">
          <div className="chatLogoBox">
            {/* <img className="helperImage" src={helper}></img> */}
            <div className="chatTitle">Ready to help!</div>
          </div>
          <div className="closeBox closeChat">
            <button onClick={() => store.dispatch(switchChat(false))}>
              &#215;
            </button>
          </div>
        </div>
      </div>
      <div className="chatContent"></div>
      <form
        className="chatForm"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          className="messageInput"
          placeholder={languageStatus ? "Message..." : "Сообщение..."}
        />
        <button className="sendMessage">
          <img src={send}></img>
        </button>
      </form>
    </div>
  );
};
