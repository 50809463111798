import { useNavigate } from "react-router-dom";
import loginLogo from "../icons/icons8-новый-пост-100 1.png";
import { useState } from "react";
import {
  store,
  switchContinue,
  switchRegistrationExchange,
} from "../Store/Store";
import ReactLoading from "react-loading";
import axios from "axios";
import { useSelector } from "react-redux";

export function ExchangerRegistration() {
  const [login, setLogin] = useState("");
  const [loading, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const languageStatus = useSelector((state) => state.currentLanguage);

  const navigate = useNavigate();

  const mailTest = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
  // const phoneTest = /^[\d\+][\d\(\)\ -]{4,14}\d$/;

  function handleClick() {
    store.dispatch(switchRegistrationExchange(false));
    document.body.style.overflow = "";
  }

  async function handleRegistration() {
    localStorage.setItem("email", "");
    localStorage.setItem("phone", "");

    let emailStatus = false;
    if (!mailTest.test(login)) {
      setError(true);
    }
    if (login.length > 0 && mailTest.test(login)) {
      setLoad(true);
      try {
        const params = new URLSearchParams();
        params.append("email", login);
        await axios
          .get("https://point1.bot-n.ru/uniq_email", {
            params: { email: login },
          })
          .then((response) => {
            if (response.data == false) {
              emailStatus = false;
              localStorage.setItem("uniq", false);
            }
            if (response.data == true) {
              emailStatus = true;
              localStorage.setItem("uniq", true);
            }
          });
        if (emailStatus == true) {
          await axios
            .get("https://point1.bot-n.ru/register_email", {
              params: { email: login },
            })
            .then((response) => {
              setLoad(false);
              document.body.style.overflow = "";

              localStorage.setItem(
                "access",
                response.data.temporary_access_token
              );

              navigate(`/transition`);

              store.dispatch(switchRegistrationExchange(false));
            });
        }
        if (emailStatus == false) {
          localStorage.setItem("email", login);
          store.dispatch(switchRegistrationExchange(false));
          store.dispatch(switchContinue(true));
        }
      } catch {
        setLoad(false);
        setError(true);
        return;
      }

      // if (mailTest.test(login)) {
      //   localStorage.setItem("email", login);
      // }

      // if (phoneTest.test(login)) {
      //   localStorage.setItem("phone", login);
      // }
    }
  }

  return (
    <div className="pop_up">
      <div className="pop_window  signUpWindow">
        <div className="closeBox">
          <button onClick={handleClick}>&#215;</button>
        </div>
        <div className="signUpContent">
          <div className="conentPart">
            {" "}
            <div className="loginTitle">
              {languageStatus
                ? "Enter your mail to receive a check"
                : "Введите почту для получения чека"}
            </div>
          </div>

          <div className="conentPart">
            {" "}
            {error && (
              <label style={{ color: "red" }}>
                {" "}
                {languageStatus
                  ? "Incorrect email"
                  : "Почта введена неверно"}{" "}
              </label>
            )}
            <div className="signUpInputBox">
              <div className="inputBox">
                <img
                  style={{ height: "30px", width: "53px" }}
                  src={loginLogo}
                ></img>
                <input
                  className="login"
                  placeholder="Email"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                ></input>
              </div>{" "}
            </div>
          </div>
          <div className="conentPart">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  minWidth: "100%",
                }}
              >
                <ReactLoading
                  type="spinningBubbles"
                  color="white"
                  height={"50px"}
                  width={"50px"}
                />
              </div>
            ) : (
              <button className="enterButton" onClick={handleRegistration}>
                Sign Up
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
